@import "../includes/_variables";
/**
 * Generic styles that apply to EVERY SINGLE visual panel - e.g. global attributes
 */

.visual-panel {
  position: relative;
  background-color: @white;
  padding-top: 30px;
  padding-bottom: 30px;
  > div {
    position: relative;
    z-index: 1;
  }
}

.panel-bg-style--light-gray {
  background-color: @redesign-light-grey;
  color: @dark-grey;
  p {
    color: @dark-grey;
  }
  a:not(.btn) {
    color: #756447 !important;
  }
}

.paragraph--type--panel__grid-layout {
  .media--type-image {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .col {
    .field--name-field-grid-column-body {
      height: 100%;
      .align-center {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }
}