/*** Styles for Social landing page ****/
@import "../includes/_variables";

.masonry-layout {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-template-rows: masonry; //womp womp, not supported by most browsers...yet, js to the rescue.
  grid-gap: 1.5rem;
  margin: auto;
  @media (min-width: @screen-lg) {
    grid-gap: 2.75rem;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  }
}

/**** Cards ****/
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: @white;
  background-clip: border-box;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 8%);
  height: max-content;
  margin-bottom: 1em;
  &:hover {
    box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 15%);

  }

  .card-link {
    display: block;
    z-index: 1;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .card-flag {
    background-color: @color-ada-gold;
    text-transform: uppercase;
    font-size: 20px;
    color: @white;
    padding: 20px 40px 20px 20px;
  }
  .card-img-top {
    img {
      flex-shrink: 0;
      width: 100%;
      height: auto;
      display: block;
    }
  }
  .card-title {
    font-family: @headline-font;
    font-weight: @font-weight-light;
    font-size: 18px;
    padding-bottom: .5rem;
  }
  .card-body {
    padding: 1em;
    flex: 1 1 auto;
    min-height: 1px;
  }
  &.card-social {
    &:nth-child(even) {
      animation-delay: 1.75s;
    }
    &:nth-child(odd) {
      animation-delay: 1.5s;
    }
    .card-title {
      color: @color-c21light;
      padding-bottom: 1em;
      letter-spacing: 1px;
    }
    .card-flag {
      width: 210px;
      padding: 20px 40px 20px 20px;
      position: absolute;
      top: 15px;
      left: 15px;
      .podcast-description {
        display: block;
        text-transform: none;
        font-size: 14px;
        padding-top: 1.5em;
      }
    }
    .card-body {
      background: @color-c21-black;
      display: flex;
      flex-direction: row;
      @media (min-width: @screen-sm) {
        padding: 2.5em;
      }
      .card-text {
        font-size: 14px;
        line-height: 1.6;
        color: @color-c21light;
      }
      .card-icon {
        width: 50px;
        height: 50px;
        background-color: @color-ada-gold;
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: center;
      }
      a {
        color: @color-c21light;
      }
    }
    &.cs-instagram {
      .card-icon{
        background-image: url(/images/portal/ionicons-instagram.svg);
      }
    }
    &.cs-facebook {
      .card-icon{
        background-image: url(/images/portal/ionicons-facebook.svg);
      }
    }
    &.cs-twitter {
      .card-icon{
        background-image: url(/images/portal/ionicons-twitter.svg);
      }
    }
    &.cs-story {
      .card-icon{
        background-color: @color-c21-black;
        background-image: url(/images/portal/logo-seal-2.svg);
        background-size: 35px;
      }
    }
    &.cs-podcast {
      .card-icon{
        background-image: url(/images/portal/icon_microphone.svg);
      }
    }
  }
  &.card-video {
    margin-top: 45px; //offset flag
    margin-bottom: 2em;
    .card-flag {
      font-family: @subhead-font;
      line-height: 1.2;
      letter-spacing: 1px;
      background-color: @color-ada-gold;
      text-transform: uppercase;
      position: absolute;
      font-size: 13px;
      width: 170px;
      height: 48px;
      color: @white;
      padding: 15px;
      top: -22px;
      @media (min-width: @screen-md) {
        font-size: 20px;
        width: 210px;
        height: 90px;
        padding: 20px 40px 20px 20px;
        top: -45px;
      }
    }
    .card-img {
      overflow: hidden;
      height: 315px;
      img {
        object-fit: cover;
        width: 100%;
      }
      @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
        img {
          height: 100%;
          object-fit: none;
          object-position: 50% 50%;
        }
      }
      @media (max-width: @screen-xs-max) {
        height: 175px;
        img {
          width: 100%;
        }
      }
    }
    .card-body {
      background: @color-c21light;
      display: flex;
      flex-direction: row;
      .card-title {
        color: @obsessed-grey;
        font-size: 20px;
      }
      .card-info {
        width: calc(~'100% - 50px');
      }
      .card-icon {
        width: 50px;
        height: 50px;
        background-image: url(/images/portal/play-btn_dark.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
