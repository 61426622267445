/**
 * Classes to add-to the overall framework (e.g. bootstrap)
 * that should have been there all along.  Nothing client specific belongs in this file.
 */

/**
 * A bootstrap compatible container that acts like container at xs & sm sizes and container-fluid for larger sizes.
 */
/*

/**
 * A fluid container at small sizes becomes a standard container for larger devices.
 */

.container-fluid-dynamic {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
  @media(min-width: @screen-sm) and (max-width: @screen-sm-max) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media(max-width: @screen-xs-max) {

  }
  @media (min-width: @screen-md) {
    padding-right: 15px;
    padding-left: 15px;
  }
  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }

  @media (min-width: 1440px) {
    width: 1410px;
  }
}

.container-dynamic > .navbar-header,
.container-dynamic > .navbar-collapse,
.container-fluid-dynamic > .navbar-header,
.container-fluid-dynamic > .navbar-collapse {
  //margin-right: -15px;
  //margin-left: -15px;
  @media screen and (min-width: @screen-sm) {
    margin-right: -30px;
    margin-left: -30px;
  }
}
@media screen and (min-width: @screen-md-min) {
  .container-dynamic > .navbar-header,
  .container-dynamic > .navbar-collapse,
  .container-fluid-dynamic > .navbar-header,
  .container-fluid-dynamic > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}

/**
 * Text align utility classes
 */
.text-left-not-xs, .text-left-not-sm, .text-left-not-md, .text-left-not-lg {
  text-align: left;
}

.text-center-not-xs, .text-center-not-sm, .text-center-not-md, .text-center-not-lg {
  text-align: center;
}

.text-right-not-xs, .text-right-not-sm, .text-right-not-md, .text-right-not-lg {
  text-align: right;
}

.text-justify-not-xs, .text-justify-not-sm, .text-justify-not-md, .text-justify-not-lg {
  text-align: justify;
}

@media (max-width: 767px) {
  .text-left-not-xs, .text-center-not-xs, .text-right-not-xs, .text-justify-not-xs {
    text-align: inherit;
  }

  .text-left-xs {
    text-align: left;
  }

  .text-center-xs {
    text-align: center;
  }

  .text-right-xs {
    text-align: right;
  }

  .text-justify-xs {
    text-align: justify;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .text-left-not-sm, .text-center-not-sm, .text-right-not-sm, .text-justify-not-sm {
    text-align: inherit;
  }

  .text-left-sm {
    text-align: left;
  }

  .text-center-sm {
    text-align: center;
  }

  .text-right-sm {
    text-align: right;
  }

  .text-justify-sm {
    text-align: justify;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .text-left-not-md, .text-center-not-md, .text-right-not-md, .text-justify-not-md {
    text-align: inherit;
  }

  .text-left-md {
    text-align: left;
  }

  .text-center-md {
    text-align: center;
  }

  .text-right-md {
    text-align: right;
  }

  .text-justify-md {
    text-align: justify;
  }
}

@media (min-width: 1200px) {
  .text-left-not-lg, .text-center-not-lg, .text-right-not-lg, .text-justify-not-lg {
    text-align: inherit;
  }

  .text-left-lg {
    text-align: left;
  }

  .text-center-lg {
    text-align: center;
  }

  .text-right-lg {
    text-align: right;
  }

  .text-justify-lg {
    text-align: justify;
  }
}

/*
.list-sm-inline {
  @media screen and (min-width: @screen-sm) {
    li {
      display: inline-block;
      padding-right: 15px;
    }
  }
}

.list-md-inline {
  @media screen and (min-width: @screen-md-min) {
    li {
      display: inline-block;
      padding-right: 15px;
    }
  }
}

.list-lg-inline {
  @media screen and (min-width: @screen-lg-min) {
    li {
      display: inline-block;
      padding-right: 15px;
    }
  }
}
*/

/**
 * System to allow multiple columns to be equal height
 */
@maximum-matching-column-height: 20000px;
@grid-gutter-width: 30px;

.row-equal-height {
  overflow: hidden;

  .col-full-height {
    padding-bottom: @maximum-matching-column-height;
    margin-bottom: -@maximum-matching-column-height + @grid-gutter-width;
  }

  @media (min-width: @screen-sm) {
    .col-sm-full-height {
      padding-bottom: @maximum-matching-column-height;
      margin-bottom: -@maximum-matching-column-height + @grid-gutter-width;
    }
  }

  @media (min-width: @screen-md-min) {
    .col-md-full-height {
      padding-bottom: @maximum-matching-column-height;
      margin-bottom: -@maximum-matching-column-height + @grid-gutter-width;
    }
  }

  @media (min-width: @screen-lg-min) {
    .col-lg-full-height {
      padding-bottom: @maximum-matching-column-height;
      margin-bottom: -@maximum-matching-column-height + @grid-gutter-width;
    }
  }
}

.grid-divider {
  [class*='col-']:not(:last-child) {
    border-bottom: 1px solid @grey;
    @media (min-width: @screen-sm) {
      border-bottom: none;
    }
  }
  @media (min-width: @screen-sm) {
    [class*='col-']:not(:last-child):after {
      background: @grey;
      width: 1px;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      min-height: 70px;
    }
  }
}
.modal-xl {
  width: 100%;
}
@media (min-width: @screen-sm) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}



