@import "../includes/_variables";
@import "../includes/mixins";
/**
 * Modal Styling
 */



/* Video Modal
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  cursor: pointer;
  visibility: hidden;
  transition: all 0.35s ease-in;
  z-index: 4;
  &.is-visible {
    visibility: visible;
    background-color: rgba(37, 37, 38, 0.7);;
  }
  &.modal-dark {
    .vertical-title {
      display: none;
      @media (min-width: @screen-md) {
        display: block;
        position: absolute;
        top: 0;
        left: 40px;
      }
    }

    .vertical-logo {
      @media (max-width: @screen-sm-max) {
        display: none;
      }
      bottom: 10%;
      svg {
        fill: @medium-grey;
      }
    }
    .modal-dialog, .modal-header {
      background-color: @obsessed-grey;
      color: @color-c21light;
      @media (min-width: @screen-md) {
        //border-left: 100px solid @color-c21-gold;
        background-image: linear-gradient(90deg, rgba(132,114,82,1) 100px, rgba(37,37,38,1) 100px);
      }
      a:not(.btn) {
        color: @white;
      }
      .close-modal {
        background: transparent;
        color: @white;
      }
    }
    .modal-dialog {
      min-height: 69vh;
      .story__wrapper {
        @media (min-width: @screen-md) {
          padding-left: 11em;
          padding-right: 6em;
        }
      }
    }
  }

  &.modal-full-width {
    padding-left: 0;
    padding-right: 0;
    .modal-dialog {
      width: 100vw;
      max-width: 100vw;
      height: 100vh;
      @media (min-width: @screen-sm) {
        max-height: calc(~"100vh - 80px");
        top: 40px;
      }
    }
  }
}

.modal-dialog {
  position: relative;
  border-radius: 0;
  background: @white;
  overflow: auto;
  cursor: default;
  width: 100%;
  height: 100%;
  @media (min-width: @screen-sm) {
    width: 80vw;
    max-height: 80vh;
    max-width: 1024px;
  }
  .btn-outline-primary {
    color: @white;
  }
  .modal-header {
    padding: 1rem 1rem 0 1rem;
    background: @white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .modal-footer {
    padding: 1em 1em 2em 1em;
    .clearfix();
    @media (min-width: @screen-sm) {
      padding-right: 6em;
    }
    .btn {
      float: right;
    }
  }
  .story__title {
    font-family: @headline-font;
    font-weight: @font-weight-light;
    font-size: 30px;
    margin-bottom: 2em;
  }
  .story__body {
    font-size: 14px;
    line-height: 1.6;
    @media (min-width: @screen-sm) {
      font-size: 16px;
    }
  }
  .story__thumb {
    padding-bottom: 10px;
    img {
      width: 100%;
    }
  }
  .story__transcript {
    padding: 1em 0;

    .transcript-text {
      background: @color-c21grey;
      padding: 1rem;
      margin: 1rem 0;
    }
  }
  .modal-content {
    padding: 1rem;
  }
  .audio_story {
    h3 {
      padding-top: 1em;
      font-weight: @font-weight-light;
      font-family: @subhead-font;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }
    .story__thumb {
      img {
        max-width: 300px;
      }
    }
  }
  .close-modal {
    cursor: pointer;
    background: @white;
    font-size: 1.5rem;
    border: none;
    transition: all ease-in-out .25s;
    &:hover {
      opacity: .8;
      transform: scale(1.3);
    }
    &:focus {
      outline: 1px solid @color-c21-gold;
    }
  }
  .js-story-type {
    display: none;
    &.active {
      display: block;
    }
  }

}

/* ANIMATIONS - currently not in use -but saving just in case!
–––––––––––––––––––––––––––––––––––––––––––––––––– */
[data-animation] .modal-dialog {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.51, 0.92, 0.24, 1.15);
}

[data-animation].is-visible .modal-dialog {
  opacity: 1;
  transition-delay: 0.2s;
}

[data-animation="slideInOutDown"] .modal-dialog {
  transform: translateY(100%);
}

[data-animation="slideInOutTop"] .modal-dialog {
  transform: translateY(-100%);
}

[data-animation="slideInOutLeft"] .modal-dialog {
  transform: translateX(-100%);
}

[data-animation="slideInOutRight"] .modal-dialog {
  transform: translateX(100%);
}

[data-animation="zoomInOut"] .modal-dialog {
  transform: scale(0.2);
}

[data-animation="rotateInOutDown"] .modal-dialog {
  transform-origin: top left;
  transform: rotate(-1turn);
}

[data-animation="mixInAnimations"].is-visible .modal-dialog {
  animation: mixInAnimations 2s 0.2s linear forwards;
}

[data-animation="slideInOutDown"].is-visible .modal-dialog,
[data-animation="slideInOutTop"].is-visible .modal-dialog,
[data-animation="slideInOutLeft"].is-visible .modal-dialog,
[data-animation="slideInOutRight"].is-visible .modal-dialog,
[data-animation="zoomInOut"].is-visible .modal-dialog,
[data-animation="rotateInOutDown"].is-visible .modal-dialog {
  transform: none;
}

@keyframes mixInAnimations {
  0% {
    transform: translateX(-100%);
  }

  10% {
    transform: translateX(0);
  }

  20% {
    transform: rotate(20deg);
  }

  30% {
    transform: rotate(-20deg);
  }

  40% {
    transform: rotate(15deg);
  }

  50% {
    transform: rotate(-15deg);
  }

  60% {
    transform: rotate(10deg);
  }

  70% {
    transform: rotate(-10deg);
  }

  80% {
    transform: rotate(5deg);
  }

  90% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
