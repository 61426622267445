@import "../includes/_variables";
@import "../includes/_framework-extension";

@import "__content_body_macros";

.c21-residential-body {
  position: relative;
}
.cms-content {
  &.modal-open {
    overflow: hidden;
  }
}

/** Scoping to content area to avoid header/footer breakage **/
#layout-main-body-content {

  // scope content body to layout-main

  .contentBodyStyles();
  /*** Page Styles ***/
  #header_block {
    margin-top: 2rem;
  }
}


/*** Header Styles - CMS Only ***/

.hub__header {
  @media (max-width: @screen-xs-max) {
    text-align: center;
  }
  .hub__header-inner {
    @media (min-width: @screen-sm) {
      display: flex;
      align-items: center;
    }
    #sitename {
      margin-bottom: 0;
      margin-left: auto;
      @media (max-width: @screen-xs-max) {
        font-size: 20px;
      }
    }
  }
}

/*** Inter-page Navigation ***/
.article-nav-wrap {
 // padding-bottom: 2rem;
  @media (min-width: @screen-sm) {
    order: 2;
    margin-left: auto;
  }
  .article-nav {
    font-family: @subhead-extended-font;
    font-size: 10px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    text-align: center;
    @media (min-width: @screen-sm) {
      text-align: right;
      padding-bottom: 5px;
    }
  }
  .article-title {
    display: none;
    @media (min-width: @screen-sm) {
      display: block;
      font-family: @headline-font;
      font-size: 18px;
      text-transform: none;
      letter-spacing: normal;
      line-height: 1;
      color: @obsessed-grey;
      text-decoration: underline;
      margin-top: -3px;
    }
  }
  a:not(.btn) {
    color: @color-ada-gold;
    &.disabled {
      color: @text-grey;
    }
  }
  &.article-footer-nav {
    width: 100%;
    margin-left: 0;
    order: 1;
    padding-bottom: 2rem;
    .article-nav.hidden-xs {
      width: 100%;
      display: flex;
      .prev-link {
        text-align: left;
      }
      .next-link {
        margin-left: auto;
      }
    }
  }
}

/*** Footer Styles - CMS Only ***/

.hub__header, .hub__footer {
  color: @color-c21-gold !important;
  font-size: 20px;
  font-weight: @font-weight-bold !important;
  font-family: @body-font;
}
#site-footer.hub__footer {
  //position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 50px;
  text-align: center;
}
.user-login-form {
  margin: 2em 0;
}


/*** Utilities ***/
.js-collapse {
  transition: all .35s ease;
  &:not(.show) {
    //display: none;
    position: relative;
    height: 0;
    overflow: hidden;
  }
}

.video-embed-field-responsive-video {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  &:before {
    display: block;
    content: "";
    padding-top: 56.25%;
  }
  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

// Used in Modals and Stories pages
.vertical-title {
  font-size: 42px;
  letter-spacing: 1.5px;
  font-weight: bold;
  line-height: .715;
  font-family: @subhead-font;
  transform: rotateZ(-90deg) translate(-157px, -76px);
  span {
    display: block;
    margin-left: 1.2em;
  }
}
.vertical-logo {
  position: absolute;
  right: 30px;
  bottom: 20%;
  svg {
    width: 18px;
    height: 160px;
    fill: @redesign-black;
  }
}