@import "../includes/_variables";
// Tabs -- Bootstrap styles to include on non-bootstrap C21 Content hub.
// -------------------------

//Need to add these variables

@line-height-base: 1rem;
@border-radius-base: 5px;
@nav-tabs-link-hover-border-color: @grey;
@nav-tabs-active-link-hover-color: @color-ada-gold;
@nav-tabs-active-link-hover-bg:  @white;
@nav-tabs-active-link-hover-border-color:  @grey;
@nav-link-padding: 15px;
@nav-link-hover-bg: @white;
@nav-disabled-link-color: #ddd;
@nav-disabled-link-hover-color: @grey;
@cursor-disabled: @grey;

// Base class
// --------------------------------------------------

.nav {
  display: block;
  margin-bottom: 0;
  padding-left: 0; // Override default ul/ol
  list-style: none;
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  > li {
    position: relative;
    display: block;

    > a {
      position: relative;
      display: block;
      padding: @nav-link-padding;
      &:hover,
      &:focus {
        text-decoration: none;
        background-color: @nav-link-hover-bg;
      }
    }

    // Disabled state sets text to gray and nukes hover/tab effects
    &.disabled > a {
      color: @nav-disabled-link-color;

      &:hover,
      &:focus {
        color: @nav-disabled-link-hover-color;
        text-decoration: none;
        background-color: transparent;
        cursor: @cursor-disabled;
      }
    }
  }

  // Open dropdowns
  .open > a {
    &,
    &:hover,
    &:focus {
      background-color: @nav-link-hover-bg;
      border-color: @link-color;
    }
  }

}

// Give the tabs something to sit on
.nav-tabs {
  border-bottom: 1px solid @grey;
  > li {
    float: left;
    // Make the list-items overlay the bottom border
    margin-bottom: -1px;

    // Actual tabs (as links)
    > a {
      margin-right: 2px;
      line-height: @line-height-base;
      border: 1px solid transparent;
      border-radius: @border-radius-base @border-radius-base 0 0;
      &:hover {
        border-color: @nav-tabs-link-hover-border-color @nav-tabs-link-hover-border-color @grey;
      }
    }

    // Active state, and its :hover to override normal :hover
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: @nav-tabs-active-link-hover-color;
        background-color: @nav-tabs-active-link-hover-bg;
        border: 1px solid @nav-tabs-active-link-hover-border-color;
        border-bottom-color: transparent;
        cursor: default;
      }
    }
  }
}