/** A base style sheet for all pages  **/
@import "mixins";
@import "_variables";

//
// Utilities
// --------------------------------------------------
.clearfix {
   clear: both;
}
.pull-right {
   float: right !important;
}
.pull-left {
   float: left !important;
}
.hidden {
   display: none !important;
   visibility: hidden !important;
}

.visible-xs, .visible-xs-inline {
   display: none;
}
@media (max-width: @screen-phone-max){
   .hide-small{
      display: none !important;
   }
   .visible-xs {
      display: block;
   }
   .visible-xs-inline {
      display: inline;
   }
   .hidden-xs {
      display: none !important;
   }
}

//
// Typography
// --------------------------------------------------
h1, .h1, h2, .h2 {
   font-family: @subhead-condensed-font;
}
h3, .h3 {
   font-family: @headline-font;
}
h4, .h4 {
   font-family: @subhead-extended-font;
}
h1, .h1 {
   font-size: 34px;
   line-height: 1em;
}
h2, .h2 {
   font-size: 20px;
}
h3, .h3 {
   font-size: 16px;
}
h4, .h4 {
   font-size: 10px;
}

.lead {
   font-size: 16px;
}
.grey {
   color: @text-grey;
}
//
// Buttons
// --------------------------------------------------
.btn {
   font-family: @subhead-font;
   font-weight: bold;
   letter-spacing: 1.6px;
   text-transform: uppercase;
   font-size: 10px;
   padding: .25em .75em;
   text-decoration: none;
   user-select: none;
   background-color: transparent;
   border: 1px solid transparent;
   margin: 0.5em .5em .5em 0;
   display: inline-block;
   text-align: center;
   vertical-align: middle;
   min-height: 27px;
   cursor: pointer;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   &-primary {
      color: @redesign-black;
      background-color: @color-c21gold;
      border-color: @color-c21gold;
      &:hover, &:focus {
         background-color: darken(@color-c21gold, 10%);
         border-color: darken(@color-c21gold, 10%);
      }
   }
   &-secondary {
      color: @white;
      background-color: @redesign-black;
      border-color: @redesign-black;
      &:hover, &:focus {
         background-color: darken(@redesign-black, 10%);
         border-color: darken(@redesign-black, 10%);
      }
   }
   &-outline-primary {
      color: @color-ada-gold;
      border-color: @color-ada-gold;
      &:hover, &:focus {
         color: @white;
         background-color: @color-ada-gold;
      }
   }
   &-outline-secondary {
      color: @redesign-black;
      border-color: @redesign-black;
      &:hover, &:focus {
         color: @white;
         background-color: @redesign-black;
      }
   }
   .svg-inline {
      vertical-align: middle;
      padding-left: 4px;
      svg {
         width: 10px;
         height: 10px;
      }
   }
}

.btn-gold {
   background: @gold;
   color: @black;
   &:hover, &:focus{
      background: lighten(@gold, 10%);
   }
   &.pagination{
      background: @grey;
      &:hover, &:focus{
         background: lighten(@grey, 10%);
      }
   }
}
.btn-orange {
   background: @white;
   color: @color-c21-gold; // @orange;
   border: 1px solid @color-c21-gold; // @orange;
   &:hover, &:focus{
      background: lighten(@white, 10%);
   }
   &.pagination{
      background: @grey;
      &:hover, &:focus{
         background: lighten(@grey, 10%);
      }
   }
}
.btn-blue {
   .border-radius(0px);
   background:@color-c21-gold; // @button-blue;
   color: @white;
   &:hover, &:focus{
      background: lighten(@gold, 10%);
   }
   &.pagination{
      background: @grey;
      &:hover, &:focus{
         background: lighten(@grey, 10%);
      }
   }
}
.btn-md{
   font-weight: 600;
   padding: .5em;
}
.btn-lg{
   padding: 1.15em 1.5em !important;
}
.btn-wide{
   @media (max-width: @screen-phone-max){
      display: block;
      box-sizing: border-box;
      width: 100%;
      text-align: center;
   }
}


//
// Forms
// --------------------------------------------------
/*input, textarea{
   padding: 7px;
   font-size:1.3em;
   color:#aaa;
   border: solid 1px #ccc;
   margin: 2px 8px 10px 2px;
}*/

//
// Tables
// --------------------------------------------------

.table-responsive {
   overflow-x: auto;
   min-height: 0.01%;
}
table {
   background-color: transparent;
   border-collapse: collapse;
   border-spacing: 0;
}
caption {
   padding-top: 8px;
   padding-bottom: 8px;
   color: #777777;
   text-align: left;
}
th {
   text-align: left;
}
.table {
   width: 100%;
   max-width: 100%;
   margin-bottom: 20px;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
   padding: 8px;
   line-height: 1.428571429;
   vertical-align: top;
   border-top: 1px solid #dddddd;
}
.table > thead > tr > th {
   vertical-align: bottom;
   border-bottom: 2px solid #dddddd;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
   border-top: 0;
}
.table > tbody + tbody {
   border-top: 2px solid #dddddd;
}
.table .table {
   background-color: #ffffff;
}
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
   padding: 5px;
}
.table-bordered {
   border: 1px solid #dddddd;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
   border: 1px solid #dddddd;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
   border-bottom-width: 2px;
}
.table-striped > tbody > tr:nth-child(odd) {
   background-color: #f9f9f9;
}
.table-hover > tbody > tr:hover {
   background-color: #f5f5f5;
}
table col[class*="col-"] {
   position: static;
   float: none;
   display: table-column;
}
table td[class*="col-"],
table th[class*="col-"] {
   position: static;
   float: none;
   display: table-cell;
}
.table-responsive {
   margin-left: -25px;
   width: 110%;
}
@media screen and (max-width: 767px) {
   .table-responsive {
      width: 100%;
      margin-bottom: 15px;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      border: 1px solid #dddddd;
   }
   .table-responsive > .table {
      margin-bottom: 0;
   }
   .table-responsive > .table > thead > tr > th,
   .table-responsive > .table > tbody > tr > th,
   .table-responsive > .table > tfoot > tr > th,
   .table-responsive > .table > thead > tr > td,
   .table-responsive > .table > tbody > tr > td,
   .table-responsive > .table > tfoot > tr > td {
      white-space: nowrap;
      .ellipsis();
      max-width: 200px;
   }
   .table-responsive > .table-bordered {
      border: 0;
   }
   .table-responsive > .table-bordered > thead > tr > th:first-child,
   .table-responsive > .table-bordered > tbody > tr > th:first-child,
   .table-responsive > .table-bordered > tfoot > tr > th:first-child,
   .table-responsive > .table-bordered > thead > tr > td:first-child,
   .table-responsive > .table-bordered > tbody > tr > td:first-child,
   .table-responsive > .table-bordered > tfoot > tr > td:first-child {
      border-left: 0;
   }
   .table-responsive > .table-bordered > thead > tr > th:last-child,
   .table-responsive > .table-bordered > tbody > tr > th:last-child,
   .table-responsive > .table-bordered > tfoot > tr > th:last-child,
   .table-responsive > .table-bordered > thead > tr > td:last-child,
   .table-responsive > .table-bordered > tbody > tr > td:last-child,
   .table-responsive > .table-bordered > tfoot > tr > td:last-child {
      border-right: 0;
   }
   .table-responsive > .table-bordered > tbody > tr:last-child > th,
   .table-responsive > .table-bordered > tfoot > tr:last-child > th,
   .table-responsive > .table-bordered > tbody > tr:last-child > td,
   .table-responsive > .table-bordered > tfoot > tr:last-child > td {
      border-bottom: 0;
   }
}


//
// Columns (not Bootstrap)
// --------------------------------------------------
.row, .last, .clear{
   &:after{
      content: '';
      display: block;
      clear: both;
   }
}
.col-3 {
    @media only screen and (min-width : 1200px){
      width: 33.333%;
      float: left;
   }
  }


/**** Accordion Styles ****/
.accordion {
   @accordion-border-color: #eaeaea;
   @accordion-padding: 1rem;
   @accordion-heading-color: @color-c21-gold; //#ff9e2c;
   @accordion-icon-color: @color-c21-gold; //#ff9e2c;

   margin: 0 auto;
   list-style: none;
   font-size: 1em;

   .accordion_wrapper {
      list-style: none;
      padding-left: 0;
    }

   .accordion_item {
      float: left;
      padding-top: @accordion-padding;
      position: relative;
      width: 100%;
   }

    .accordion_item {
      border-bottom: 1px solid @accordion-border-color;
      margin-bottom: 1px;
      padding-bottom: @accordion-padding / 2;
     &:first-child{ border-top: 1px solid @accordion-border-color; }
     &:last-of-type {
        .clearfix();
      }

       h4.primary-section-header{
         padding: 0;
      }

       .panel-content{
          padding: 1.5em 0;
          line-height: 1.75em;

          .pdp-info-proptype {
             padding-bottom: 1em;
          }
       }

      // The accordion icon
      span.toggle-icon {
         font-size: 1.75em;
         color: darken(@grey, 15%);
         margin-top: @accordion-padding;
         position: absolute;
         right: .5rem;
         top: 0;
         transition: all 0.2s ease-in;
         &:after{
            content: '–';
         }
      }

      input[type=checkbox] {
         position: absolute;
         cursor: pointer;
         width: 100%;
         z-index: 1;
         opacity: 0;

         &:not(:checked) {
            // Ensures panels are closed until checked
            &~div.panel {
               float: left;
               margin: 5px 0;
               max-height: 0;
               opacity: 0;
               transform: translate(0, 50%);
               .panel-content *{
                  max-height: 0;
                  table {display: none;}
               }
            }

            // Swap icon on selection
            &~span.toggle-icon {
               &:after{
                  content: '+';
               }
            }
         }

      }
   }
}


/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}

.ui-widget { font-family: Trebuchet MS, Tahoma, Verdana, Arial, sans-serif; font-size: 1.1em; }
.ui-widget .ui-widget { font-size: 1em; }
.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button { font-family: Trebuchet MS, Tahoma, Verdana, Arial, sans-serif; font-size: 1em; }
.ui-widget-content { border: 1px solid #dddddd; background: #eeeeee url(/images/jquery/ui-bg_highlight-soft_100_eeeeee_1x100.png) 50% top repeat-x; color: #333333; }
.ui-widget-content a { color: #333333; }
.ui-widget-header {
   border: 1px solid #a2c0e2;
   background: #dfebf9;
   color: @dark-text;
   font-weight: bold;
   border-radius: 3px;
   a { color: #ffffff; }
}

.ui-datepicker {
   width: 17em;
   display: none;
   margin-top: 4px;
   padding: 8px 8px 0;
   box-shadow: 0 0 4px rgba(0,0,0,.7);
}
.ui-datepicker .ui-datepicker-header { position:relative; padding:.2em 0; }
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next { position:absolute; top: 2px; width: 1.8em; height: 1.8em; }
.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover { top: 1px; }
.ui-datepicker .ui-datepicker-prev { left:2px; }
.ui-datepicker .ui-datepicker-next { right:2px; }
.ui-datepicker .ui-datepicker-prev-hover { left:1px; }
.ui-datepicker .ui-datepicker-next-hover { right:1px; }
.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span { display: block; position: absolute; left: 50%; margin-left: -8px; top: 50%; margin-top: -8px;  }
.ui-datepicker .ui-datepicker-title { margin: 0 2.3em; line-height: 1.8em; text-align: center; }
.ui-datepicker .ui-datepicker-title select { font-size:1em; margin:1px 0; }
.ui-datepicker select.ui-datepicker-month-year {width: 100%;}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year { width: 49%;}
.ui-datepicker table {width: 100%; font-size: .9em; border-collapse: collapse; margin:0 0 .4em; }
.ui-datepicker th { padding: .7em .3em; text-align: center; font-weight: bold; border: 0;  }
.ui-datepicker td { border: 0; padding: 1px; }
.ui-datepicker td span, .ui-datepicker td a { display: block; padding: .2em; text-align: right; text-decoration: none; }
.ui-datepicker .ui-datepicker-buttonpane { background-image: none; margin: .7em 0 0 0; padding:0 .2em; border-left: 0; border-right: 0; border-bottom: 0; }
.ui-datepicker .ui-datepicker-buttonpane button { float: right; margin: .5em .2em .4em; cursor: pointer; padding: .2em .6em .3em .6em; width:auto; overflow:visible; }
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current { float:left; }

.ui-icon { display: block; text-indent: -99999px; overflow: hidden; background-repeat: no-repeat; }
.ui-icon { width: 16px; height: 16px; background-image: url(/images/jquery/ui-icons_222222_256x240.png); }
.ui-icon-circle-triangle-e { background-position: -48px -192px; }
.ui-icon-circle-triangle-s { background-position: -64px -192px; }
.ui-icon-circle-triangle-w { background-position: -80px -192px; }
.ui-icon-circle-triangle-n { background-position: -96px -192px; }

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
   border: 1px solid transparent;
}
.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover {
   border: 1px solid #a2c0e2;
}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
   border: 1px solid #a2c0e2;
   background: #fff;
   color: @color-c21-gold; // @blue;
   font-weight: bold;
}
