/*** Styles for Written, Audio and Video Stories pages ****/
@import "../includes/_variables";

.node--type-written-story, .node--type-audio-story, .node--type-video-story {
  .vertical-title, .vertical-logo {
    display: none;
    @media (min-width: @screen-md) {
      display: block;
    }
  }

  h2.story__header {
    #layout-main-body-content & {
      font-size: 30px;
      font-weight: 100;
      text-transform: none;
      color: @obsessed-grey;
      letter-spacing: 1.2px;
      padding-bottom: 1em;
      &:after {
        display: none;
      }
      @media (min-width: @screen-md) {
        padding-left: 115px;
      }
    }
  }
  .story__wrapper {
    margin-bottom: 50px;
    min-height: 150px;

    @media (min-width: @screen-md) {
      .col-sm-6 {
        &:first-child {
          padding-left: 8em;
        }
        &:nth-child(2) {
          padding-right: 4em;
        }
      }
    }
  }
  .story__thumb {
    margin: 1.5em auto;
    @media (min-width: @screen-sm) {
      margin-top: 0;
    }
    img {
      width: 100%;
      //max-width: 405px;
      height: auto;
      display: block;
      margin: auto;
    }
  }
  .story__transcript {
    margin-top: 2em;
    margin-bottom: 2em;
    .btn {
      margin-bottom: 1em;
    }
  }
}
.node--type-video-story {
  .story__wrapper {
    @media (min-width: @screen-md) {
      .col-sm-12 {
        padding-left: 8em;
        padding-right: 4em;
      }
    }
    .field--name-field-video-link {
      margin-bottom: 1.5em;
    }
  }
}


/* Social Storeis Landing Page Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/*** Video Panel ***/
#block-views-block-agent-story-features-block-1 {
  background: url(/images/portal/sparkle-bg.jpg) no-repeat center;
  background-size: cover;
  padding: 50px 0;
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .col {
    padding-left: 15px;
    padding-right: 15px;
    flex: 0 0 auto;
    width: 100%;
  }
  button.modal-video {
    background: none;
    border: none;
  }
  .layout-1-up {
    .col {
      max-width: 400px;
      margin: auto;
      @media (min-width: @screen-sm) {
        max-width: 700px;
      }
      .card-img {
        height: 160px;
        @media (min-width: @screen-sm) {
          height: 320px;
        }
        img {
          width: 100%;
          height: auto;
          object-fit: initial;
        }
      }
    }
    @media (min-width: @screen-sm) {
      .col {
        flex: 0 0 auto;
        width: 85%;
        margin: auto;
      }
    }
    @media (min-width: @screen-md) {
      .col {
        width: 65%;

      }
    }
  }
  .layout-2-up, .layout-4-up {
    @media (min-width: @screen-sm) {
      .col {
        flex: 0 0 auto;
        width: 50%;
      }
    }
    @media (min-width: @screen-lg) {
      .card-img img {
        width: 100%;
      }
    }
  }
  .layout-3-up, .layout-6-up {
    @media (min-width: @screen-sm) {
      .col {
        flex: 0 0 auto;
        width: 33.333%;
        .card-img {
          height: 370px;
          img {
            height: 100%;
          }
        }
      }
    }
  }
  .layout-5-up {
    @media (min-width: @screen-sm) {
      .col {
        flex: 0 0 auto;
        width: 33.333%;
        &:nth-child(1), &:nth-child(2) {
          width: 50%;
        }
      }
    }
    @media (min-width: @screen-lg) {
      .col {
        &:nth-child(1), &:nth-child(2) {
          .card-img img {
            width: 100%;
          }
        }
      }
    }
  }
}

/*** Social Panel ***/
#block-views-block-agent-story-list-block-1 {
  padding: 90px 0;
}


/*.view-recent-videos {
  .container {
    @media (min-width: @screen-sm) {
      display: flex;
      flex-wrap: wrap;
    }
    .node--type-video-story {
      .teaser-content {
        margin-bottom: 2em;
        @media (min-width: @screen-sm) {
          max-width: 450px;
        }
        .teaser-featured-img {
          img {
            width: 100%;
            height: auto;
            margin-bottom: 1em;
          }
        }
        .article-teaser {
          font-size: 16px;
          color: @text-grey;
        }
      }

      .video-link {
        font-family: @headline-font;
        font-size: 28px;
        text-transform: uppercase;
        &:after {
          content: "";
          position: relative;
          height: 2px;
          width: 60px;
          background: @color-c21darkgold;
          display: block;
          margin: 1rem 0 2rem;
        }
      }
    }
  }
}*/