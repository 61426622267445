@import "../includes/_variables";

/****
 Classes dynamically scoped within CK Editor and the main content body
 *** DO NOT INCLUDE ANYTHING BUT MACROS IN THIS FILE! ***
****/

.contentBodyStyles() {
  font-size: 16px;
  line-height: 24px;
  color: @body-text;

  //
  // Typography
  // --------------------------------------------------

  h1, .h1, h2, .h2 {
    margin-bottom: 1rem;
    color: @obsessed-grey;
  }
  h2, .h2, h3, .h3 {
    font-family: @headline-font;
    font-weight: normal;
    text-transform: uppercase;
    &:after {
      content: "";
      position: relative;
      height: 2px;
      width: 120px;
      background: @color-c21darkgold;
      display: block;
      margin: 1rem 0 2rem;
    }
    a {
      text-decoration: none !important;
      &:hover, &:focus {
        color: @color-c21-black !important;
      }
    }
    &.text-align-center {
      &:after {
        margin: 1rem auto 2rem;
      }
    }
  }

  h2, .h2 {
    font-size: 34px;
    letter-spacing: 1.36px;
    line-height: 1.15;
  }

  h3, .h3 {
    font-size: 28px;
    color: @obsessed-grey;
    letter-spacing: 1.12px;
    padding-top: 1em;
    line-height: 1.15;
    &:after {
      width: 60px;
      margin: 1rem 0;
    }
  }

  h4, .h4 {
    color: @obsessed-grey;
    font-size: 24px;
    font-family: @headline-font;
    font-weight: normal;
    padding-top: 1em;
    margin-bottom: 1rem;
  }

  .gold-bar {
    font-family: @headline-font;
    line-height: .8;
    text-transform: uppercase;
    color: @obsessed-grey;
    &:after {
      content: "";
      position: relative;
      height: 2px;
      width: 60px;
      background: @color-c21darkgold;
      display: block;
      margin: .5rem 0 1rem;
    }
  }

  p {
    .gold-bar {
      font-size: 18px;
    }
  }
  .text-align-center {
    .gold-bar {
      &:after {
        margin: .5rem auto 1rem;
      }
    }
  }
  p, ul, ol {
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 1rem;
  }
  p, li {
    a:not(.btn) {
      color: @color-ada-gold;
      text-decoration: underline dotted @color-ada-gold;
    }
  }

  ul, ol {
    li {
      padding-bottom: 10px;
    }
  }

  hr {
    &.divider_1, &.divider_2, &.divider_3 {
      border: 0;
      margin-top: 1rem;
      margin-bottom: 1rem;
      width: 100%;
    }
    &.divider_1 {
      height: 40px;
      background-image: url(/images/portal/c21-pattern_grey.png);
      background-position: top left;
      background-repeat: repeat;
    }
    &.divider_2 {
      background-image: url(/images/portal/deco-logo.svg);
      background-size: 40px;
      background-position: center;
      background-repeat: no-repeat;
      height: 40px;
      //change the color to gold
      filter: invert(77%) sepia(27%) saturate(337%) hue-rotate(6deg) brightness(87%) contrast(88%);
    }
    &.divider_3 {
      height: 10px;
      background: @color-c21light;
    }
  }

  blockquote {
    font-style: italic;
    font-size: 24px;
    line-height: 1.21;
    color: #847252;
    text-align: center;
    max-width: 850px;
    margin: auto;
    padding: 10px 0 20px 0;
    &:before {
      content: '';
      background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiNFRUVFRUU7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzcxLjcsMzE5LjRoNzhWOTZIMjg3Ljl2MjIzLjRsNTEuNSw5Ni42aDc4TDM3MS43LDMxOS40eiBNMTQwLjIsMzE5LjRoODIuOVY5Nkg2Mi4ydjIyMy40bDUwLjYsOTYuNmg3OAoJTDE0MC4yLDMxOS40eiIvPgo8L3N2Zz4=");
      background-repeat: no-repeat;
      width: 100px;
      height: 78px;
      display: block;
      margin-right: 8px;
      background-size: 115px;
      background-position: -8px -18px;
    /*@include media-breakpoint-up(sm){
      float: left;
    }*/
    }
  }

  .btn {
    font-family: @subhead-font;
    font-weight: bold;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    font-size: 10px;
    padding: .25em .75em;
    text-decoration: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    margin: 0.5em .5em .5em 0;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    min-height: 27px;
    cursor: pointer;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    &-primary {
      color: @redesign-black;
      background-color: @color-c21gold;
      border-color: @color-c21gold;
      &:hover, &:focus {
        background-color: darken(@color-c21gold, 10%);
        border-color: darken(@color-c21gold, 10%);
      }
    }
    &-secondary {
      color: @white;
      background-color: @redesign-black;
      border-color: @redesign-black;
      &:hover, &:focus {
        background-color: darken(@redesign-black, 10%);
        border-color: darken(@redesign-black, 10%);
      }
    }
    &-primary-alt {
      color: @white;
      background-color: @color-ada-gold;
      border-color: @color-ada-gold;
      &:hover, &:focus {
        background-color: darken(@color-ada-gold, 10%);
        border-color: darken(@color-ada-gold, 10%);
      }
    }
    &-outline-primary {
      color: @color-ada-gold;
      border-color: @color-ada-gold;
      &:hover, &:focus {
        color: @white;
        background-color: @color-ada-gold;
      }
    }
    &-outline-secondary {
      color: @redesign-black;
      border-color: @redesign-black;
      &:hover, &:focus {
        color: @white;
        background-color: @redesign-black;
      }
    }
    &.btn-lg {
      font-size: 18px;
      padding: 0.5em 1.4em;
    }
    &.btn-xl {
      padding: 0.75rem 1.25rem;
      font-size: 1.5rem;
    }
    .svg-inline {
      vertical-align: middle;
      padding-left: 4px;
      svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  /**** Cross Promotion Block ***/
  figure {
    width: min-content;
    figcaption {
      font-size: 12px;
      line-height: 1.3;
    }
  }

  .align-center {
    margin: 1rem auto;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .align-right {
    margin: 1rem 0 1rem 1rem;
  }
  .align-left {
    margin: 1rem 1rem 1rem 0;
  }
  .align-left, .align-right {
    &:before,
    &:after {
      display: table;
      content: "";
      line-height: 0;
    }
    &:after {
      clear: both;
    }
  }

  div[data-entity-embed-display="view_mode:node.embedded_cross_promotion"],
  div[data-entity-embed-display="view_mode:node.embedded_cross_promotion"] + figcaption {
    background-color: @color-c21-black;
    color: @white;
    max-width: 385px;
    font-size: 16px;
    line-height: 24px;
    padding: 0 1rem 1rem 1rem;
  }

  .node--view-mode-embedded-cross-promotion {
    max-width: 385px;
    img {
      width: 100%;
    }
    h3 {
      color: @white;
      padding: 1rem;
      &:after {
        margin-bottom: 0;
      }
      a {
        color: @white;
        &:hover, &:focus {
          color: @white !important;
        }
      }
    }
  }
}

#layout-main-body-content .btn-primary {
  color: #38383a;
  background-color: #beaf87;
  border-color: #beaf87;
}
#layout-main-body-content .btn {
  font-family: Typold,Helvetica,Arial,sans-serif;
  font-weight: 700;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  font-size: 10px;
  padding: 0.25em 0.75em;
  text-decoration: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  margin: 0.5em 0.5em 0.5em 0;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  min-height: 27px;
  cursor: pointer;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}