@import "../includes/_variables";
@import "../includes/mixins";

@light-gold: #F8F7F2;
@border-gold: #DCD3BD;
@text-on-gold: #707070;

.form-section__full-width {
  padding: 1.5em;
  label {
    font-family: @subhead-font;
    font-weight: bold;
    display: block;
    font-size: 12px;
    span {
      font-weight: normal;
      color: #777055;
    }
  }
  input, textarea, select {
    width: 100%;
    border: 1px solid @border-gold;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-bottom: 8px;
    font-size: 14px;
    font-family: @body-font;
    line-height: 1.5;
    color: @text-on-gold;
    background-color: @white;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  }
  input:disabled {
    background-color: #F8F5F2;
    opacity: 1;
  }
  &.bg-gold {
    background-color: @light-gold;
    color: @text-on-gold;
  }
}
.franchise-contact-section {
  .formThanks {
    background-color: @light-gold;
    color: @text-on-gold;
    padding: 2em 1em;
    margin-bottom: 4em;
  }
}
/**** copied from corporate sites contact module c21_corporate_contact ****/
.whiteWashedBackground {
  position: absolute;
  z-index: 999999;
  background-color: #EEE;
  top: 0; left: 0;
  height: 100%;
  width: 100%;
  outline: 5px solid #fff;
  text-align: center;
}

.loadingAnimation {
  width: 100%;
  position: absolute; top: 0; left: 0;
  background-image: url(/images/portal/snake-black-32x32.gif);
  background-repeat: no-repeat;
  background-position: center center;
  height: 50px;
}
