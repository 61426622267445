/**
 * @file
 * Styles for breadcrumbs.
 */

.breadcrumb {
  padding-bottom: 0.5em;
}
.breadcrumb ol {
  margin: 0;
  padding: 0;
}
[dir="rtl"] .breadcrumb ol {
  /* This is required to win over specificity of [dir="rtl"] ol */
  margin-right: 0;
}
.breadcrumb li {
  display: inline;
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: #322925; //set for
  a {
    color: #322925;
  }
}
/* IE8 does not support :not() and :last-child. */
.breadcrumb li:before {
  content: " > ";
  color: #fff;
}
.breadcrumb li:first-child:before {
  content: none;
}