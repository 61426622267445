.glyphicon.glyphicon-star-empty.half {
   .pngIconFallback(0,0, '/images/c21/propsearch/school_rating_half_star');
   display: inline-block;
   width: 12px;
   height: 12px;
}
.glyphicon.glyphicon-star {
   .pngIconFallback(0,0, '/images/c21/propsearch/school_rating_star');
   display: inline-block;
   width: 12px;
   height: 12px;
}
.glyphicon.glyphicon-star-empty {
   .pngIconFallback(0,0, '/images/c21/propsearch/school_rating_star_empty');
   display: inline-block;
   width: 12px;
   height: 12px;
}
.clear(){
   &:after{
      content: '';
      display: block;
      clear: both;
   }
}
.clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    // Fixes Opera/contenteditable bug:
    // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}
.animated-fast {
   -webkit-animation-duration: 0.3s;
   animation-duration: 0.3s;
   -webkit-animation-fill-mode: both;
   animation-fill-mode: both;
}
.text-shadow (@string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
  text-shadow: @string;
}
.box-shadow (@string) {
  -webkit-box-shadow: @string;
  -moz-box-shadow:    @string;
  box-shadow:         @string;
}
.drop-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
  -webkit-box-shadow:	@x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow:	@x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow:		@x @y @blur @spread rgba(0, 0, 0, @alpha);
}
.inner-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
  -webkit-box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow:    inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow:         inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.box-sizing (@type: border-box) {
  -webkit-box-sizing: @type;
  -moz-box-sizing:    @type;
  box-sizing:         @type;
}

.border-radius (@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius:    @radius;
  border-radius:         @radius;

  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}
.border-radiuses (@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
  -webkit-border-top-right-radius:    @topright;
  -webkit-border-bottom-right-radius: @bottomright;
  -webkit-border-bottom-left-radius:  @bottomleft;
  -webkit-border-top-left-radius:     @topleft;

  -moz-border-radius-topright:        @topright;
  -moz-border-radius-bottomright:     @bottomright;
  -moz-border-radius-bottomleft:      @bottomleft;
  -moz-border-radius-topleft:         @topleft;

  border-top-right-radius:            @topright;
  border-bottom-right-radius:         @bottomright;
  border-bottom-left-radius:          @bottomleft;
  border-top-left-radius:             @topleft;

  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}

.opacity (@opacity: 0.5) {
  -webkit-opacity: 	@opacity;
  -moz-opacity: 		@opacity;
  opacity: 		@opacity;
}

.gradient (@startColor: #eee, @endColor: white) {
  background: @startColor;
  background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
  background: -webkit-linear-gradient(top, @startColor, @endColor);
  background: -moz-linear-gradient(top, @startColor, @endColor);
  background: -ms-linear-gradient(top, @startColor, @endColor);
  background: -o-linear-gradient(top, @startColor, @endColor);
}
.horizontal-gradient (@startColor: #eee, @endColor: white) {
  background-color: @startColor;
  background-image: -webkit-gradient(linear, left top, right top, from(@startColor), to(@endColor));
  background-image: -webkit-linear-gradient(left, @startColor, @endColor);
  background-image: -moz-linear-gradient(left, @startColor, @endColor);
  background-image: -ms-linear-gradient(left, @startColor, @endColor);
  background-image: -o-linear-gradient(left, @startColor, @endColor);
}

.placeholder(@color) {
  &::-webkit-input-placeholder {color: @color;}
  &:-moz-placeholder {color: @color;}
  &::-moz-placeholder {color: @color;}
  &:-ms-input-placeholder {color: @color;}
}

.panel-separator() {
  border-bottom: 40px solid #000000;
}
.background-fixed() {
  background-size: cover;
  background-attachment: fixed;
  -ms-behavior: url(/js/eco/backgroundsize.min.htc);
}
.vertical-align() {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.transition (@transition) {
   transition: @transition;
   -webkit-transition: @transition;  
   -moz-transition:    @transition;
   -ms-transition:     @transition; 
   -o-transition:      @transition;  
}

.transform(@string){
   transform: @string;
   -webkit-transform: @string;
   -moz-transform: 	 @string;
   -ms-transform: 		 @string;
   -o-transform: 		 @string;
}
.translate (@x, @y:0) {
   transform:       translate(@x, @y);
   -webkit-transform:       translate(@x, @y);
   -moz-transform: 	 translate(@x, @y);
   -ms-transform: 		 translate(@x, @y);
   -o-transform: 		 translate(@x, @y);
}

.img-retina(@file-1x; @file-2x; @width-1x; @height-1x) {
   display: inline-block;
   width: @width-1x;
   height: @height-1x;
   background: url("@{file-1x}") no-repeat;
   background-size: @width-1x @height-1x;

   @media
   only screen and (-webkit-min-device-pixel-ratio: 2),
   only screen and (   min--moz-device-pixel-ratio: 2),
   only screen and (     -o-min-device-pixel-ratio: 2/1),
   only screen and (        min-device-pixel-ratio: 2),
   only screen and (                min-resolution: 192dpi),
   only screen and (                min-resolution: 2dppx) {
      background-image: url("@{file-2x}");
      background-size: @width-1x @height-1x;
   }
}

.pngIconFallback(@x, @y, @filename) {
   background: url('@{filename}.png');
   background-image:  -webkit-linear-gradient(transparent, transparent),
   url('@{filename}.svg');
   background-image:  -o-linear-gradient(transparent, transparent),
   url('@{filename}.svg');
   background-image:  -moz-linear-gradient(transparent, transparent),
   url('@{filename}.svg');
   background-image: url('@{filename}.svg'),
   linear-gradient(transparent, transparent);
   background-repeat: no-repeat;
   //Because some browsers need the x y and some don't.
   background-position-x: @x;
   background-position-y: @y;
   background-position: @x @y;
}


.ellipsis() {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
.anti-ellipsis() {
   white-space: normal;
   overflow: visible;
}

/* mixin for multiline */
.multiLineEllipsis(@lineHeight: 1.2em, @lineCount: 1, @bgColor: white){
   overflow: hidden;
   position: relative;
   line-height: @lineHeight;
   max-height: @lineHeight * @lineCount;
   text-align: justify;
   /*margin-right: -1em;*/
   padding-right: 1em;
   display: block;
&:before {
   content: '...';
   position: absolute;
   right: 0;
   bottom: 0;
}
&:after {
   content: '';
   position: absolute;
   right: 0;
   width: 1em;
   height: 1em;
   margin-top: -0.8em;
   background: @bgColor;
   }
}
