//
// Variables - these are shared between C21.com and C21 Content Hub CMS
// --------------------------------------------------

//colors
@gold: #beaf87; //#relentless-gold
@black: #000;
@white: #fff;
@obsessed-grey: #252526;
@grey:   #D3D3D3;
@medium-grey: #808285;
@body-text: #767676;
@dark-text: #53534D;
@text-grey: #9e9d9d;
@dark-grey: #575757;
@light-grey: #c7c7c7;
@button-blue: #1e64b4;
@blue:  #495f88;
@slate: #273035;
@off-white: #fffbf3;
@purple: #AC14AC;
@orange: #beaf87; //#ea4f2f
@filter-grey: #535353;
@red: #9e1e1e;
@error: #b60000;
@burned-orange: #BF5700;
@green: #4b8978;
@rose: #9d5e6f;
@lime: #b9c493;

@color-c21-gold: @gold;
@color-c21gold: @gold;
@color-c21-black: @obsessed-grey;
@color-text-on-dark: #9d9d9d;
@color-c21darkgold: #A19276;
@color-c21-darkest-gold: #5F5544;
@color-ada-gold: #847252;
@color-c21grey: #38383a;
@color-c21light: #e6e7e8;


@redesign-gold: @color-c21-gold;
@redesign-black: @color-c21grey;
@redesign-grey: #808285;
@redesign-light-grey: @color-c21light;
@redesign-white: #e6e7e8;

@unvisited-text-color: @color-c21-gold; // #2366b2;
@property-flag-color: @color-c21-gold; // #eeb22b;
@property-card-text-color: #3f3f3f;
@font-weight-light: 300;
@font-weight-semi-bold: 600;
@font-weight-bold: 700;
@redesign-light-grey: @color-c21light;


//HPOA & Financing variables
@prop-font-black: #4e4e4e;
@prop-font-blue: @color-c21darkgold; // #1e64b4;
@prop-font-orange: @color-c21-gold; //  #e94d2d;

@hpoa-font-yellow: @color-c21-gold; // #edc267;
@hpoa-font-blue: @color-c21grey; //#8ec5e2;


//Sizing
@screen-phone-max: 767px;
@screen-tablet-min: (@screen-phone-max + 1px);
@screen-tablet-max: 1023px;
@screen-desktop-min: (@screen-tablet-max + 1px);
@screen-desktop-large: 1600px;
@c21-header-size: 92px;

//Typography
@headline-font: "BarlowSemiCondensed", Helvetica, Arial, sans-serif;
@subhead-condensed-font: "TypoldCondensed","Typold","Helvetica","Arial",sans-serif;
@subhead-font: "Typold", Helvetica, Arial, sans-serif;
@subhead-extended-font: "TypoldExtended","Typold", Helvetica, Arial, sans-serif;
@body-font: "Oakes", Helvetica, Arial, sans-serif;
@prop-card-font: @subhead-font;
@default-font: @body-font;
@link-color: @color-c21-gold;
/** Standard Responsive Steps **/
@screen-xxs: 320px;
@screen-xs: 480px;
@screen-xs-min: @screen-xs;
@screen-sm: 768px;
@screen-sm-min: @screen-sm;
@screen-md: 992px;
@screen-md-min: @screen-md;
@screen-lg: 1200px;
@screen-lg-min: @screen-lg;
@screen-xl: 1440px;
@screen-xs-max: (@screen-sm-min - 1);
@screen-sm-max: (@screen-md-min - 1);
@screen-md-max: (@screen-lg-min - 1);