@import "../includes/_variables";
/**
 *  Navigation styles for secondary content navigation.
 */

.hub__content-menu-spacer {
  height: 50px;
  @media (max-width: @screen-sm) {
    height: 0;
  }
}

.hub__content-menu {
  background: @white;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.08);
  width: 100%;
  z-index: 4;

  // Copy of rule from _navigation.less
  position: fixed;
  @media (max-width: @screen-sm) {
    position: relative;
  }

  .subnavbar-toggler {
    background: @color-ada-gold;
    border: none;
    width: 50px;
    height: 50px;
    cursor: pointer;
    .icon-bar {
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;
      background-color: @white;
      margin-left: 8px;
      margin-right: auto;
      &.middle-bar {
        width: 18px;
      }
      &.bottom-bar {
        width: 14px;
      }
      + .icon-bar {
        margin-top: 4px;
      }
    }
  }
  /*** Sidebar Nav ***/
  #contenHubOffscreenNav {
    overflow: auto;
    background-color: @color-ada-gold;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    z-index: 201;
    padding: 1.5em 0;
    opacity: 0;
    display: none;
    min-width: 285px;
    transform: translateX(-100%);
    transition: opacity 0.5s ease-in-out, transform 0.42s ease-in-out;
    font-family: @subhead-font;
    letter-spacing: .5px;
    &.show {
      opacity: 1;
      transform: translateX(0);
    }
    .dropdown-menu {
      display: block;
    }

    .menu-close {
      background: transparent;
      border: none;
      color: @white;
      position: absolute;
      right: 15px;
      cursor: pointer;
    }

    .menu {
      margin-top: 28px;
      padding-left: 0;
      font-size: 12px;
      list-style: none;
      li {
        &.first-tier-menu {
         > a {
           text-transform: uppercase;
           font-size: 13px;
           padding: 11px 15px 8px 15px;
          }
          &.active {
            > a {
              background-color: @black;
            }
          }
          ul {
            padding-left: 0;
          }
        }
      }
      .menu-level-1 {
        > li {
          > a {
            padding-left: 25px;
          }
        }
      }
      .menu-level-2 {
        > li {
          > a {
            padding-left: 35px;
            &:before {
              content: '\2022';
              padding-right: 10px;
            }
          }
        }
      }
      a {
        color: @white;
        background: @color-ada-gold;
        letter-spacing: 0.98px;
        padding: 8px 15px;
        display: block;
        &:hover, &:focus {
          background: #a19276;
        }
      }

      .dropdown-submenu {
        .dropdown-toggle {
          &:after {
            content: '+';
            width: 20px;
            height: 20px;
            color: @white;
            font-weight: bold;
            display: inline-block;
            background: @color-c21-black;
            text-align: center;
            float: right;
            line-height: 1.75;
          }
        }
        .dropdown-menu {
          display: none;
        }
        &.open {
          .dropdown-toggle {
            &:after {
              content: '-';
            }
          }
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  }
  /*** Top Bar Nav ***/
  #contenHubNav {
    font-family: @subhead-font;
    letter-spacing: .5px;
    ul {
      padding-left: 0;
      list-style: none;
    }
    .submenu-1 {
      display: inline-block;
      position: relative;
    }
    .first-tier-menu, .submenu-0 {
      display: none;
      &.active {
        display: flex;
      }
      > a {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1.25px;
        color: @color-ada-gold;
        padding: 15px 20px;
      }
      .dropdown-menu.menu-level-2 {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        float: left;
        min-width: 180px;
        padding: 0 0;
        margin: 0;
        font-size: 14px;
        text-align: left;
        list-style: none;
        background-color: @white;
        background-clip: padding-box;
        box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.16);
      }
      .menu-level-1 {
        display: none;
        @media (min-width: @screen-sm) {
          display: flex;
          align-items: center;
        }
        .submenu-1 {
          position: relative;
          a {
            display: block;
            padding: 15px 10px;
            color: @body-text;
            font-size: 12px;
            position: relative;
            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 4px;
              bottom: 0;
              right: 0;
              background: @color-ada-gold;
              opacity: 0;
              -webkit-transform: scaleX(0);
              transform: scaleX(0);
              transition: all .72s ease-in-out;
            }
          }
          &.active {
            > a {
              color: @color-c21-black;
              font-weight: bold;
              &:before {
                opacity: 1;
                transform: scaleX(1);
              }
            }
          }
          &.open {
            .dropdown-menu {
              display: block;
            }
          }
        }
        .submenu-2 {
          a {
            &:hover, &:focus {
              background: @color-c21darkgold;
              color: @white !important;
            }
          }
        }
      }

    }
  }
}