@import "../includes/_variables";
.hero-bg-mobile {
  display: block;
  @media (max-width: @screen-xs-max) {
    height: 195px;
  }
  @media (min-width: @screen-md) {
    display: none;
  }
}

.hero-bg-desktop {
  display: none;
  @media (min-width: @screen-md) {
    display: block;
  }
}

.breadcrumb {
  ol {
    li {
      &:before {
        content: '';
        width: 5px;
        height: 8px;
        display: inline-block;
        vertical-align: middle;
        padding-left: 5px;
        padding-right: 5px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4.8' height='8' viewBox='0 0 4.8 8'%3E%3Cpath id='Path_14714' data-name='Path 14714' d='M-276.2,18.991l-.743.731-3.9-3.84a.511.511,0,0,1,0-.731.532.532,0,0,1,.743,0Zm-1.1,1.1-2.8,2.756a.532.532,0,0,1-.743,0,.511.511,0,0,1,0-.731l2.8-2.756Z' transform='translate(280.997 -15)' fill='%23fff'/%3E%3C/svg%3E");
        background-position: center center;
        background-repeat: no-repeat;
      }
      &:first-child {
        a {
          font-weight: bold;
        }
      }
    }
  }
}
/**
 * Header Heroes
 */
.layout-hero {
  position: relative;
  @media (min-width: @screen-sm) {
    display: flex;
    align-items: flex-end;
  }
  .field--name-field-field-hero-bg-media {
    @media (min-width: @screen-sm) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .media {
      height: 100%;
    }

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      /*@media (max-width: 768px) {
        height: 100%;
      }
      @media (min-width: @screen-md) {
        height: 100%;
      }*/
    }
  }

  .hero-text-box {
    position: relative;
    color: @white;
    z-index: 1;
    padding: 1.5rem;
    display: flex;
    h1 {
      font-family: @headline-font;
      font-weight: @font-weight-light;
      text-transform: uppercase;
      font-size: 25px;
    }
    .hero-text {
      padding-right: 8px;
      @media (min-width: @screen-sm) {
        padding-right: 12px;
      }
    }
  }
  .logo-seal {
    width: 51px;
    height: 54px;
    margin-left: auto;
    margin-top: -23px;
    overflow: hidden;
    flex-shrink: 0;
    @media (min-width: @screen-sm) {
      width: 90px;
      height: 96px;
    }
    svg {
      width: 100%;
      height: auto;
      margin-top: -11px;
      fill: @color-c21-black;
      @media (min-width: @screen-sm) {
        margin-top: -21px;
      }
    }
  }

  &.header-hero-small-standard {
    background-color: #38383a;
    min-height: 235px;
    overflow: hidden;
    @media (min-width: @screen-sm) {
      min-height: 425px;
    }

    .hero-text-box {
      background-color: @color-c21darkgold;
      padding-bottom: 40px;
      h1 {
        margin-top: 0;
        @media (min-width: @screen-sm) {
          font-size: 50px;
          letter-spacing: 2px;
        }
        &.long-title {
          font-size: 18px;
          @media (min-width: @screen-sm) {
            font-size: 35px;
          }
        }
      }
      @media (min-width: @screen-sm) {
        min-width: 50%;
        max-width: 700px;
        padding-left: ~"calc((100vw - 700px) / 2)";
      }
      @media (min-width: @screen-md) {
        padding-left: ~"calc((100vw - 950px) / 2)";
      }
      @media (min-width: @screen-lg) {
        padding-left: ~"calc((100vw - 1145px) / 2)";
      }
      @media (min-width: @screen-xl) {
        padding-left: ~"calc((100vw - 1390px) / 2)";
      }

      .breadcrumb {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: @obsessed-grey;
        padding: 4px 1.5rem;
        //Making sure this only displays one line
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @media (min-width: @screen-sm) {
          padding-left: ~"calc((100vw - 700px) / 2)";
        }
        @media (min-width: @screen-md) {
          padding-left: ~"calc((100vw - 950px) / 2)";
        }
        @media (min-width: @screen-lg) {
          padding-left: ~"calc((100vw - 1145px) / 2)";
        }
        @media (min-width: @screen-xl) {
          padding-left: ~"calc((100vw - 1390px) / 2)";
        }
        li {
          a {
            color: @white;
          }
        }

      }
    }
  }
  &.header-hero-small-alternate {
    background-color: #38383a;
    min-height: 235px;
    overflow: hidden;
    @media (min-width: @screen-sm) {
      min-height: 350px;
    }
    .hero-text-box {
      background-color: @color-c21-black;
      @media (max-width: @screen-xs-max) {
        margin-left: -15px;
        margin-right: -15px;
      }
      @media (min-width: @screen-sm) {
        margin-bottom: 3rem;
        margin-left: 3rem;
        max-width: 600px;
      }
      .breadcrumb {
        ol {
          max-width: 425px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          li {
            color: @white;
            a {
              color: @white;
            }
          }
        }
      }
      h1 {
        color: @white;
        font-family: @subhead-font;
        text-transform: uppercase;
        @media (max-width: @screen-xs-max) {
          display: inline-block;
          padding-bottom: .5rem;
        }
        @media (min-width: @screen-sm) {
          font-size: 42px;
          text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

          &.title-top {
            position: absolute;
            margin-top: -80px;
            margin-left: -3rem;
          }
          &.title-bottom{
            margin-top: -45px;
            margin-left: -1rem;
            margin-bottom: 1rem;
            &.no-top {
              margin-top: -45px;
            }
          }
        }

      }
      p {
        color: @color-c21light;
        font-size: 14px;
        @media (min-width: @screen-sm) {
          max-width: 357px;
        }
      }
    }
    .logo-seal {
      svg {
        fill: @color-c21-gold;
      }
    }
  }
}

