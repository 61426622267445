@import "../includes/_variables";
/**
 * Overrides for Drupal utilities that display in the site front end and need styled in the front-end theme for users
 */

.user-logged-in {
  // Drop fixed position of C21 menu when logged in to drupal
  .site-header, .hub__content-menu {
    position: relative;
  }
  .site-header-spacer, .hub__content-menu-spacer {
    height: 0;
    overflow: hidden;
  }
}

.tabs--primary.nav-tabs {
  margin-top: 45px;
  .nav-item {
    padding-bottom: 0 !important;
  }

  a {
    text-decoration: none !important;
  }
}

.moderation-dashboard {
  @extend .container-fluid-dynamic;
}

form.user-login-form,
form.user-pass {
  font-size: 16px;
  @extend .container-fluid-dynamic;
  padding-top: 15px;
  padding-bottom: 30px;
  label {
    font-weight: bold;
    margin-top: 1em;
  }

  input[type=text], input[type=password] {
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #525252;
    background-color: #F8F8F8;
    background-clip: padding-box;
    border: 1px solid #E3E3E3;
    border-radius: 0;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    @media (max-width: @screen-sm) {
      width: 100%;
    }
  }
  .form-submit {
    margin-top: 1em;
    color: #fff;
    background-color: #5F5544;
    border-color: #5F5544;
    padding: .375rem 1.5rem;
    border-radius: 0
  }
}

#edit-name--description, #edit-pass--description {
  font-style: italic;
  color: #9e9d9d;
  padding-top: 6px;
}


//// Pagination
//nav {
//  margin: auto;
//  ul {
//    @extend .pagination;
//    li {
//      @extend .page-item;
//      padding: 5px 1em;
//      a {
//        //@extend .page-link;
//      }
//    }
//  }
//}


.pager__items {
  clear: both;
  text-align: center;
}
.pager__item {
  display: inline;
  padding: 0.5em;
}
.pager__item.is-active {
  font-weight: bold;
}


.messages {
  position: relative;
  padding: 1rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: .25rem;
  border: 1px solid transparent;
  &.messages--status {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
  }
}

/**
 * @file
 * Alignment classes for text and block level elements.
 */

.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}
.text-align-justify {
  text-align: justify;
}

/**
 * Alignment classes for block level elements (images, videos, blockquotes, etc.)
 */
.align-left {
  float: left;
}
.align-right {
  float: right;
}
.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.hidden {
  display: none;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px,1px,1px,1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}

.visually-hidden.focusable:active,.visually-hidden.focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
  width: auto;
}